<template>
  <div class="my-order">
    <div class="back" @click="back"><< Back to previous page</div>

    <div class="order">
      <div class="all d-flex">
        <section class="col-9">commodity</section>
        <section class="col-3">quantity</section>
      </div>
    </div>
    <div class="goods" v-if="cart.ordersgoods">
      <div class="item d-flex">
        <section class="col-4">
          <img :src="cart.ordersgoods.thumb" alt="" />
        </section>
        <section class="col-4">
          {{ cart.ordersgoods.description }}
        </section>
        <section class="col-2" style="color: #666666">
          {{ cart.ordersgoods.item_name }}
        </section>
        <section class="col-2">{{ cart.ordersgoods.item_num }}</section>
      </div>
    </div>

    <div class="my-top">
      <div class="my-top-num">order number: {{ cart.orders_no }}</div>
      <div class="my-top-0">
        <section v-if="cart.status == 0" style="color: #ff7f00">
          Wait for confirm
        </section>
        <section v-if="cart.status == 1" style="color: #ff7f00">
          Returning
        </section>
        <section v-if="cart.status == 2" style="color: #d34039">
          Return failed
        </section>
        <section v-if="cart.status == 3" style="color: #ff7f00">
          In exchange
        </section>
        <section v-if="cart.status == 4" style="color: #d34039">
          Exchange failed
        </section>
        <section v-if="cart.status == 5" style="color: #71b247">
          Returned
        </section>
        <section v-if="cart.status == 6" style="color: #71b247">
          Completed
        </section>
      </div>
      <div class="my-top-1">
        <section v-if="cart.status == 2 || cart.status == 4">
          <span
            @click="reapplyService(cart.id)"
            style="color: #999999; border: 1px solid #999999"
          >
            Re-apply</span
          >
        </section>
        <section>
          <span
            style="color: #71b247; border: 1px solid #71b247; padding: 0.15rem"
            @click="confirmReceiptReplacement(cart.id)"
            v-if="cart.status == 3"
          >
            Confirm receipt
          </span>
        </section>
      </div>
    </div>
    <div class="info">
      <div class="info-block">
        <section>Order information</section>
        <section>
          <p>order time:</p>
          <span>{{ cart.create_time }}</span>
        </section>
      </div>
    </div>
    <div class="o-title">Service order information</div>
    <!-- <div class="order-info d-flex">
      <section class="col-3">
        <p>Commodity handling     </p>
        <p>Reason for application  </p>
        <p>Problem Description     </p>
        <p>Recipient information    </p>
      </section>
      <section class="col-9">
        <p>{{ cart.type == 1 ? "退货" : "换货" }}</p>
        <p>{{ cart.reason }}</p>
        <p>{{ cart.description }}</p>
        <p>{{ $store.state.userinfo.address }}</p>
      </section>
    </div> -->

    <table border="1" class="order-info" style="border: 1px solid #e0e0e0">
      <tr>
        <td style="width: 30%">Commodity handling</td>
        <td style="width: 70%">
          {{ cart.type == 1 ? "Return goods" : "Reissue goods" }}
        </td>
      </tr>
      <tr>
        <td>Reason for application</td>
        <td>{{ cart.reason }}</td>
      </tr>
      <tr>
        <td>Problem Description</td>
        <td>{{ cart.description }}</td>
      </tr>
      <tr>
        <td>Recipient information</td>
        <td>{{ $store.state.userinfo.address }}</td>
      </tr>
      <tr v-if="cart.picture">
        <td>picture</td>
        <td>
          <img v-for="item in cart.picture" :src="item" alt="" />
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import {
  getServiceByid,
  reapplyService,
  confirmReceiptReplacement,
  getServicelist,
} from "network/order";
export default {
  data() {
    return {
      cart: {},
    };
  },
  created() {
    getServiceByid(this.$route.query.id).then((res) => {
      if (res.data.code == 1) {
        this.cart = res.data.data;
      }
    });
  },
  methods: {
    back() {
      this.$router.go(-1);
    },

    reapplyService(id) {
      reapplyService(id).then((res) => {
        if (res.data.code == 1) {
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
          getServiceByid(this.$route.query.id).then((res) => {
            if (res.data.code == 1) {
              this.cart = res.data.data;
            }
          });
        } else {
          this.$notify({
            message: res.data.msg,
            type: "error",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });
    },

    confirmReceiptReplacement(id) {
      confirmReceiptReplacement(id).then((res) => {
        if (res.data.code == 1) {
          this.$notify({
            message: res.data.msg,
            type: "success",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
          getServiceByid(this.$route.query.id).then((res) => {
            if (res.data.code == 1) {
              this.cart = res.data.data;
            }
          });
        } else {
          this.$notify({
            message: res.data.msg,
            type: "error",
            duration: 3000,
            position: "top-left",
            showClose: false,
            offset: 300,
          });
        }
      });
    },
  },
};
</script>
<style scoped>
p {
  padding: 0;
  margin: 0;
}
.back {
  font-size: 0.4rem;
  color: #ff7f00;
  cursor: pointer;
  text-decoration: underline;
}
.my-top {
  border: 1px solid #ededed;
  border-top: 3px solid #71b247;
  margin: 0.5rem 0;
  padding: 0.5rem 0;
  color: #999999;
  font-size: 0.35rem;
  text-align: center;
}
.my-top-0 {
  color: #71b247;
  font-size: 0.5rem;
  margin-top: 0.75rem;
}
.my-top-1 {
  margin: 0.75rem 0 0.5rem 0;
}
.my-top-1 span {
  color: #ff7f00;
  padding: 0.15rem 0.25rem;
  border: 1px solid #ff7f00;
  cursor: pointer;
  border-radius: 0.1rem;
}
.info {
  font-size: 0.35rem;
  color: #4c4c4c;
  border: 1px solid #e0e0e0;
  padding: 0.4rem 5%;
}

.info-block section {
  display: flex;
  margin-bottom: 0.25rem;
}
.info-block p {
  min-width: 2rem;
}

.o-title {
  font-size: 0.45rem;
  color: #4c4c4c;
  padding: 0.25rem 0;
}
.order-info {
  color: #4c4c4c;
  font-size: 0.35rem;
  width: 100%;
  margin-bottom: 2rem;
}
.order-info td img {
  width: 1.8rem;
  margin-right: 0.25rem;
  /* height: 2rem; */
}

.order-info td {
  padding: 0.15rem 0.25rem;
}

.order {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin-top: 0.5rem;
}

.all {
  padding: 0.25rem;
  background: #f2f2f2;
  font-size: 0.35rem;
  color: #666666;
}
.all section {
  text-align: center;
  padding: 0;
  margin: 0;
}
.goods section {
  padding: 0 0.25rem;
  margin: 0;
  font-size: 0.35rem;
  display: flex;
  align-items: center;
}
.goods section img {
  width: 80%;
  margin: 0.25rem 10%;
}
.item {
  border: 1px solid #ededed;
}
</style>